import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import React from 'react'

import Link from '../Link'
import Wrapper from '../UI/Wrapper'
import { BreadcrumbContainer } from './styles'

export interface BreadcrumbLink {
  target?: string
  text: string
}
interface BreadcrumbProps {
  links: BreadcrumbLink[]
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const { links } = props

  return (
    <BreadcrumbContainer>
      <Wrapper>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <NavigateNextIcon className="activeLink" fontSize="inherit" />
          }
        >
          <Link directUrl="/">
            <span className="activeLink">Home</span>
          </Link>
          {links?.map((link: any) => (
            <Typography
              className={link.target ? 'activeLink' : 'current'}
              color="inherit"
              key={`breadcrumb${link.text}`}
              variant="body1"
            >
              {link.target ? (
                <Link directUrl={link.target}>{link.text}</Link>
              ) : (
                link.text
              )}
            </Typography>
          ))}
        </Breadcrumbs>
      </Wrapper>
    </BreadcrumbContainer>
  )
}

export default Breadcrumb
