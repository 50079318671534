import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const BreadcrumbContainer = styled.div`
  display: block;
  background-color: white;
  padding: 12px 0;

  .activeLink {
    color: ${rspTheme.palette.text.primary};
  }

  .current {
    color: ${rspTheme.palette.primary.light};
  }

  a {
    color: inherit;
    text-decoration-color: transparent;
    transition: all 0.2s ease-in-out;

    &:hover {
      text-decoration-color: inherit;
    }
  }

  @media (max-width: ${rspTheme.mobileNavBreakpoint}) {
    display: none;
  }
`
